<template>
  <v-container
    id="policies"
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
      >
        {{ tab.text }}
        <v-icon>{{ tab.icon }}</v-icon>
      </v-tab>

      <v-tabs-items
        v-model="mainTab"
        class="transparent"
      >
        <v-tab-item>
          <policies />
        </v-tab-item>

        <v-tab-item>
          <base-material-card
            icon="mdi-plus"
            color="secondary"
            title="Add new policy"
          >
            <add-policy />
          </base-material-card>
        </v-tab-item>
      </v-tabs-items>
    </base-material-tabs>
  </v-container>
</template>

<script>
  export default {
    components: {
      Policies: () => import('./Policies'),
      AddPolicy: () => import('./AddPolicy'),
    },

    data: () => ({
      mainTab: 0,
      tabs: [
        { text: 'Policies', icon: 'mdi-counter' },
        { text: 'Add New Policy', icon: 'mdi-plus' },
      ],
    }),
  }
</script>
